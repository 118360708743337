html,
body,
#root {
  height: 100%;
}

:root {
  --accent: rgb(0, 202, 78);
  font-family: "TRIAL Phantom Sans 0.8", Inter, sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#nprogress {
  position: relative;
  z-index: 10000;
}

#nprogress .spinner {
  display: none !important;
}

#nprogress .bar {
  background: #0065da !important;
  height: 10px !important;
}

#nprogress .peg {
  display: none !important;
}

.navigator * {
  font-family: sans-serif;
  font-size: 24px;
}

.navigator {
  list-style: none;
  padding: 0;
}

.navigator button {
  appearance: none;
  border: solid 1px;
  border-radius: 3px;
  /* color: dodgerblue; */
  padding: 2px 4px;
  line-height: auto;
  background: none;
  cursor: pointer;
}

.navigator button:focus {
  outline: none;
}

.navigator .questions button {
  --color: rgb(96, 115, 151);
  color: var(--color);
  border: solid 1px var(--color);
  padding: 2px 10px 3px;
  font-size: 0.75em;
}

.navigator .questions button:hover {
  background-color: rgb(195, 213, 231);
}

.selector-item-wrapper {
  /* opacity: 0.15;
  filter: blur(3px);
  transition: all 300ms ease; */
  display: none;
}

.selector-item-wrapper.active {
  /* opacity: 1; */
  /* filter: blur(0px); */
  display: block;
}

.selector-item-wrapper.done button {
  text-decoration: line-through;
  color: var(--accent);
}

.navigator button.close {
  color: purple;
  font-weight: 700;
}

.hGroup {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 0.5rem;
}

.hGroup.center {
  align-items: center;
}

.hGroup.between {
  justify-content: space-between;
}

.vGroup {
  display: grid;
  align-content: start;
  gap: 1rem;
}

/** Temp styles */
.app-dialog {
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  border: solid 1px;
  padding: 1rem;
  transform: translate3d(-50%, -50%, 0);
}

.circular-progress {
  width: 40px;
}

.circular-progress path {
  fill: none;
  stroke: black;
  stroke-width: 20;
  stroke-dasharray: 234;
  stroke-dashoffset: 468;
  animation: dash 1.5s linear forwards infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

nav {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-container {
  background-color: white;
  border-bottom: dashed 1px var(--accent);
  top: 0;
  position: sticky;
  z-index: 10;
}

nav a {
  text-decoration: none;
  color: inherit;
}

nav a.active {
  color: var(--accent);
}

button,
input,
textarea {
  font-family: inherit;
  font-size: 1rem;
  border-radius: 3px;
  padding: 0.5rem;
  border: none;
}

button {
  appearance: none;
  background-color: rgb(238, 238, 238);
  border: none;
  text-align: left;
  cursor: pointer;
}

button:hover {
  background-color: rgb(202, 202, 202);
}

button.muted {
  background-color: tomato;
}
